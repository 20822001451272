/**
 * This is a component that is used to frame a component on a page.
 * It centers content on the page and sets the correct padding for the screen size.
 */

import { ReactNode } from "react";
import classNames from "classnames";

export const PageSlot = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div className={classNames("flex justify-center", className)}>
      <div className="max-width-section custom-scrollbar grow overflow-x-auto overflow-y-hidden">{children}</div>
    </div>
  );
};
